import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';
import { FakeDbService } from 'app/fake-db/fake-db.service';
import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';
import { LoginpageGuard } from './loginpage.guard';
import { InnerpageGuard } from './innerpage.guard';
import { MatSnackBarModule } from '@angular/material/snack-bar';

// Import your AvatarModule
import { AvatarModule } from 'ngx-avatar';


const appRoutes: Routes = [
    {
        path: 'apps',
        loadChildren: () => import('./main/apps/apps.module').then(m => m.AppsModule)
    },
    // {
    //     path: 'pages',
    //     loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
    // },
    {
        path: 'ui',
        loadChildren: () => import('./main/ui/ui.module').then(m => m.UIModule)
    },

    {
        path: 'login',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
        canActivate: [LoginpageGuard]
    },
    {
        path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [InnerpageGuard]
    },
    {
        path: 'brands', loadChildren: () => import('./my-brands/my-brands.module').then(m => m.MyBrandsModule),
        canActivate: [InnerpageGuard]
    },
    {
        path: 'stores', loadChildren: () => import('./my-stores/my-stores.module').then(m => m.MyStoresModule),
        canActivate: [InnerpageGuard]
    },
    {
        path: 'distributors', loadChildren: () => import('./my-distributors/my-distributors.module').then(m => m.MyDistributorsModule),
        canActivate: [InnerpageGuard]
    },
    { path: 'catalogue',
    loadChildren: () => import('./catalogue/catalogue.module').then(m => m.CatalogueModule),
    canActivate: [InnerpageGuard] },
    { path: 'linesheet', loadChildren: () => import('./linesheets/linesheets.module').then(m => m.LinesheetsModule),canActivate: [InnerpageGuard] },
    { path: 'orders', loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule),canActivate: [InnerpageGuard] },
    { path: 'payments', loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsModule),canActivate: [InnerpageGuard] },
    { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),canActivate: [InnerpageGuard] },
    { path: 'notifications', loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule),canActivate: [InnerpageGuard] },
    { path: 'cart', loadChildren: () => import('./cart/cart.module').then(m => m.CartModule),canActivate: [InnerpageGuard] },
    { path: 'pages', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),canActivate: [InnerpageGuard] },
    { path: 'b2c', loadChildren: () => import('./b2-c/b2-c.module').then(m => m.B2CModule),canActivate: [InnerpageGuard] },
    { path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),canActivate: [InnerpageGuard] },
    { path: 'upload', loadChildren: () => import('./uploads/uploads.module').then(m => m.UploadsModule) },
    {
        path: '**',
        redirectTo: '/login'
    },
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        [RouterModule.forRoot(appRoutes, {
          onSameUrlNavigation: 'reload'
        })],
        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),

        AvatarModule,

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        AppStoreModule
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
