import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Applications',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'dashboards',
                title: 'Dashboards',
                translate: 'NAV.DASHBOARDS',
                type: 'item',
                icon: 'dashboard',
                url: '/dashboard'
            },
            {
                id: 'brands',
                title: 'Brands',
                // translate: 'NAV.BRANDS',
                type: 'item',
                icon: 'label',
                url: '/brands'
            },
            {
                id: 'stores',
                title: 'Stores',
                type: 'item',
                icon: 'store',
                url: '/stores'
            },
            {
                id: 'distributors',
                title: 'Distributor',

                type: 'item',
                icon: 'store',
                url: '/distributors'
            },
            {
                id: 'upload',
                title: 'File Uploads',
                type: 'item',
                icon: 'cloud_upload',
                url: '/upload/file/catalogue'
            },
            {
                id: 'orders',
                title: 'Orders',
                type: 'item',
                icon: 'receipt',
                url: '/orders'
            },
            // {
            //     id: 'payments',
            //     title: 'Payments',
            //     type: 'item',
            //     icon: 'payment',
            //     url: '/payments'
            // },
            // {
            //     id: 'reports',
            //     title: 'Reports',
            //     type: 'item',
            //     icon: 'pie_chart',
            //     url: '/reports'
            // },
            // {
            //     id: 'cart',
            //     title: 'Cart',
            //     type: 'item',
            //     icon: 'shopping_cart',
            //     url: '/cart'
            // },
            // {
            //     id: 'mail',
            //     title: 'Mail',
            //     translate: 'NAV.MAIL.TITLE',
            //     type: 'item',
            //     icon: 'email',
            //     url: '/apps/mail',
            //     badge: {
            //         title: '25',
            //         translate: 'NAV.MAIL.BADGE',
            //         bg: '#F44336',
            //         fg: '#FFFFFF'
            //     }
            // },
            // {
            //     id: 'calendar',
            //     title: 'Calendar',
            //     translate: 'NAV.CALENDAR',
            //     type: 'item',
            //     icon: 'today',
            //     url: '/apps/calendar'
            // },
            // {
            //     id: 'chat',
            //     title: 'Chat',
            //     translate: 'NAV.CHAT',
            //     type: 'item',
            //     icon: 'chat',
            //     url: '/apps/chat',
            //     badge: {
            //         title: '13',
            //         bg: '#09d261',
            //         fg: '#FFFFFF'
            //     }
            // },
            // {
            //     id: 'linesheets',
            //     title: 'Line Sheets',
            //     // translate: 'NAV.BRANDS',
            //     type: 'item',
            //     icon: 'all_inbox',
            //     url: '/linesheet'
            // },
            // {
            //     id: 'contacts',
            //     title: 'Contacts',
            //     translate: 'NAV.CONTACTS',
            //     type: 'item',
            //     icon: 'account_box',
            //     url: '/apps/contacts'
            // }
            // {
            //     id: 'to-do',
            //     title: 'To-Do',
            //     translate: 'NAV.TODO',
            //     type: 'item',
            //     icon: 'check_box',
            //     url: '/apps/todo',
            //     badge: {
            //         title: '3',
            //         bg: '#FF6F00',
            //         fg: '#FFFFFF'
            //     }
            // },
        ]
    },
    // {
    //     id: 'b2c',
    //     title: 'E-Commerce',
    //     type: 'group',
    //     icon: 'pages',
    //     children: [
    //         {
    //             id: 'config',
    //             title: 'Homepage',
    //             type: 'collapsable',
    //             icon: 'local_mall',
    //             children: [
    //                 {
    //                     id: 'banner',
    //                     title: 'Banners',
    //                     type: 'item',
    //                     url: '/b2c/Configuration/banners'
    //                 },
    //                 {
    //                     id: 'collections',
    //                     title: 'Collections',
    //                     type: 'item',
    //                     url: '/b2c/Configuration/collections'
    //                 },
    //                 {
    //                     id: 'products',
    //                     title: 'Products',
    //                     type: 'item',
    //                     url: '/b2c/Configuration/products'
    //                 }
    //             ]
    //         }
    //     ]
    // },


];
