import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    Token: any;
    userData: any;
    // Production
    companyId = '5f2b8c736047c32705e97d82';

     // localhost
     // companyId = '5f238b96b136b48ba3f15b3d';
      // companyId = '5f264d0c78379703e2fc5e8a';

      // baseUrl = 'http://localhost:5001/api/v0/';
    baseUrl = 'https://ikapi.intrakraft.com/api/v0/';

    constructor(public router: Router,public http: HttpClient) {
        this.Token = localStorage.getItem('Token');
        this.userData = JSON.parse(localStorage.getItem('userData'));
    }

    getBrandDataByCode(brandCode){
      return this.http.get(this.baseUrl + 'brandProfile/brandDetailsByBrandCode/' + brandCode);
    }

    getConnectedDistributorsByBrandId(brandId):Observable<any>{
      return this.http.get(this.baseUrl + 'connection/distributorsOfBrandById/' + brandId);
    }

    getConnectedRetailersByBrandId(brandId):Observable<any>{
      return this.http.get(this.baseUrl + 'connection/retailersOfBrandById/' + brandId);
    }

    getBrandsByUserId():Observable<any>{

      const body = new FormData();
      body.append('companyId',this.companyId);
      body.append('userId',this.userData._id);

      return this.http.post(this.baseUrl + 'brandProfile/brandsByUser',body);

    }

    getBrandsWareHousesByBrandId(brandId):Observable<any>{

      return this.http.get(this.baseUrl + 'brandWarehouse/warehousesByBrand/' + brandId);

    }

    logout() {
        this.Token = '';
        this.userData = '';
        localStorage.clear();
        this.router.navigate(['/login']);
    }
}
